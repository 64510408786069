<template>
    <vs-table search stripe :max-items="10" pagination :data="dataTransaction" :noDataText="'Không có dữ liệu'" :key="currentPage">
        <template slot="thead">
            <vs-th style="font-size:13px" class="text-center bg-primary text-white"  v-for="(item, itemIndex) in headerTable" :key="itemIndex">
                <!-- <div class="vx-row">
                    <div class="w-full flex"> -->
                        <span>{{ item.title }}</span>
                        <!-- <vs-dropdown vs-trigger-click class="cursor-pointer" v-show="itemIndex == 3">
                            <span class="font-bold">{{ item.title }}</span>
                            <feather-icon icon='ChevronDownIcon' svgClasses='h-4 w-4' class='ml-1' />
                            <vs-dropdown-menu class="w-32">
                                <vs-dropdown-item @click="handlePaymentType(type.value)" class="pl-2" v-for="(type, indexType) in PaymenTypeOptions" :key="indexType">{{ type.label }}</vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown> -->
                    <!-- </div> -->
                    <!-- <div class="w-full text-center" v-if="itemIndex == 3">
                        <small>{{ paymentType && paymentType.includes("qr") ? 'MoMo' : "Quản lý" }}</small>
                    </div> -->
                <!-- </div> -->
            </vs-th>
        </template>
        <template slot-scope="{data}">
            <tbody>
                <vs-tr class="hoverFlat" :key="indextr" v-for="(compareData, indextr) in data">
                    <vs-td class="md:w-2/12"> {{ compareData.STT }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.orderId }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.transId }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.amount }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.payType && compareData.payType.toLowerCase().includes('Admin') ? 'Quản lý duyệt' : 'MoMo' }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.orderInfo }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.createdDate }} </vs-td>
                    <vs-td class="md:w-2/12" style="min-width:120px"> 
                        <span class="badge bg-success text-white" v-if="compareData.message.includes('Successful')">{{ $t('statusActive.success') }}</span>
                        <span class="badge bg-danger text-white" v-else>{{ $t('statusActive.failure') }}</span>
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
export default {
    props: {
        dataTransaction: [],
        headerTable: [],
        currentPage: 0,
        paymentType: null
    },
    data(){
        return {
            PaymenTypeOptions: [
                {label: 'Momo', value: 'qr'},
                {label: 'Admin duyệt', value: 'admin'},
            ]
        }
    },
    methods: {
        handlePaymentType(type){
            this.paymentType = (type == "qr" ? 'Momo' : 'Admin duyệt');
            this.$eventBus.$emit("paymentType", type);
        }
    }
}
</script>

<style>
.badge {
    padding: 5px;
    font-size: 12px;
    border-radius: 15px;
}
</style>