<template>
    <vs-table search stripe :max-items="10" pagination :data="dataTransaction" :noDataText="'Không có dữ liệu'" :key="currentPage">
        <template slot="thead">
            <vs-th style="font-size:13px" class="text-center bg-primary text-white"  v-for="(item, itemIndex) in headerTable" :key="itemIndex">
                <div class="vx-row">
                    <div class="w-full flex">
                        <span class="font-bold ml-4" v-if="itemIndex != 6">{{ item.title }}</span>
                        <vs-dropdown vs-trigger-click class="cursor-pointer" v-show="itemIndex == 6">
                            <span class="font-bold ml-2" style="font-size:12px">{{ item.title }}</span>
                            <feather-icon icon='ChevronDownIcon' svgClasses='h-4 w-4' class='ml-1' />
                            <vs-dropdown-menu class="w-32">
                                <vs-dropdown-item @click="handlePaymentType(type.value)" class="pl-2" v-for="(type, indexType) in PaymenTypeOptions" :key="indexType">{{ type.label }}</vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <div class="w-full text-center" v-if="itemIndex == 6">
                        <small>{{ paymentType && paymentType.includes("momo") ? 'MoMo' : "Quản lý duyệt" }}</small>
                    </div>
                </div>
            </vs-th>
        </template>
        <template slot-scope="{data}">
            <tbody>
                <vs-tr class="hoverFlat" :key="indextr" v-for="(compareData, indextr) in data">
                    <vs-td class="md:w-2/12"> {{ compareData.STT }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.cameraPackage.id }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.cameraPackage.packageName }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.cameraResponseDTO ? compareData.cameraResponseDTO.cameraName : '' }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.cameraPackage.amount }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.cameraPackage.s3_quota }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.modifiedDate }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.paymentType }} </vs-td>
                    <vs-td class="md:w-2/12" style="min-width:150px"> 
                        <span class="badge bg-success text-white" v-if="compareData.status === 1"> {{ $t('statusActive.agree') }} </span>
                        <span class="badge bg-warning text-white" v-if="compareData.status === 2"> {{ $t('statusActive.decline') }} </span>
                        <span class="badge bg-danger text-white" v-if="compareData.status === 3"> {{ $t('statusActive.cancel') }} </span>
                        <span class="badge bg-info text-white" v-if="compareData.status === 4"> {{ $t('statusActive.waitingExpire') }} </span>
                        <span class="badge bg-danger text-white" v-if="compareData.status === 5"> {{ $t('statusActive.abort') }} </span>
                        <span class="badge bg-dark text-white" v-if="compareData.status === 6"> {{ $t('statusActive.expries') }} </span>
                        <span class="badge bg-primary text-white" v-if="compareData.status === 0"> {{ $t('statusActive.waiting') }} </span>
                        <span class="badge bg-danger text-white" v-if="compareData.status === 7"> {{ $t('statusActive.failure') }} </span>
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
export default {
    props: {
        dataTransaction: [],
        headerTable: [],
        currentPage: 0
    },
    data(){
        return {
            PaymenTypeOptions: [
                {label: 'Quản lý duyệt', value: "admin"},
                {label: 'MoMo', value: "momo"},
            ],
            paymentType: null
            
        }
    },
    methods: {
        handlePaymentType: function(type){
            this.paymentType = type;
            this.$eventBus.$emit("filter-package", type);
        }
    }
}
</script>

<style lang="scss">
.bg-info {
    background-color: #6c757d;
}
.badge {
    padding: 5px;
    font-size: 12px;
    border-radius: 15px;
}
@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 16px;
    }

    50%,
    100% {
        top: 16px;
        height: 4px;
    }
}
.lds-facebook {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    top: -3px;
    float: revert;
    padding-left: 25px;
}

.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 4px;
    width: 3px;
    background: #FCB71E;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
    left: 4px;
    animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
    left: 10px;
    animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
    left: 16px;
    animation-delay: 0s;
}

</style>