<template>
    <div id="coontainer">
        <div class="vx-row">
            <div class="vx-col w-full">
                <span class="font-bold" style="font-size:18px">{{ $t('navMenu.transactionHistory') }}</span>
            </div>
        </div>
        <div class="vx-card p-6">
            <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full">
                    <label class="label-input">{{ $t('navMenu.time') }}</label>
                    <date-range-picker
                        class="w-full"
                        :locale-data="{format: 'dd/mm/yyyy' }"
                        :opens="'right'"
                        @update="dateRangePicker"
                        v-model="dateRange">
                    </date-range-picker>
                </div>
                <!-- <div class="vx-col md:w-1/3 w-full">
                    <label class="label-input">Đơn vị</label>
                    <v-select class="w-full" v-model="organizationModel" :options="organizationOption" :clearable="false"></v-select>
                </div> -->
                <div class="vx-col md:w-1/4 w-full">
                    <label class="label-input">{{ $t('navMenu.transactionType') }}</label>
                    <v-select v-model="transactionSelect" :options="transactionsType" class="w-full"></v-select>
                </div>
                 <div class="vx-col md:w-1/4 w-full" v-if="isSelect">
                    <label class="label-input">{{ $t('navMenu.status') }}</label>
                    <v-select v-if="payloadTransaction.templateType === 1" :disabled="!isSelect" v-model="statusTransaction" :options="statusTransactionOption"></v-select>
                    <v-select v-if="payloadTransaction.templateType === 2" :disabled="!isSelect" v-model="statusTransactionPackage" :options="statusTransactionPackageOption"></v-select>
                    <v-select v-if="payloadTransaction.templateType === 5" :disabled="!isSelect" v-model="statusTransactionPayment" :options="statusTransactionPaymentOption"></v-select>
                </div>
                <div class="vx-col w-full md:w-1/4 mt-6">
                    <vs-button class="w-full" icon-pack="feather" icon="icon-search" @click="getData">{{ $t('navMenu.search') }}</vs-button>
                </div>
            </div>
            <!-- <div class="vx-row mt-4 " v-if="isSelect">
                <div class="vx-col md:w-1/2 w-full">
                    <label class="label-input">Trạng thái</label>
                    <v-select v-if="payloadTransaction.templateType === 1" :disabled="!isSelect" v-model="statusTransaction" :options="statusTransactionOption"></v-select>
                    <v-select v-if="payloadTransaction.templateType === 2" :disabled="!isSelect" v-model="statusTransactionPackage" :options="statusTransactionPackageOption"></v-select>
                    <v-select v-if="payloadTransaction.templateType === 5" :disabled="!isSelect" v-model="statusTransactionPayment" :options="statusTransactionPaymentOption"></v-select>
                </div>
                <div class="vx-col w-full md:w-1/2" v-if="payloadTransaction.templateType == 2">
                    <label class="label-input">Camera</label>
                    <v-select v-model="selectCameraModel" :options="selectCameraOption"></v-select>
                </div>
            </div> -->
            <!-- <div class="vx-row mt-4">
                <div class="vx-col w-full">
                    <vs-button class="w-full" icon-pack="feather" icon="icon-search" @click="getData">TÌM KIẾM</vs-button>
                </div>
            </div> -->
        </div>

        <!-- <div class="vx-card p-6 mt-4" v-if="payloadTransaction.templateType == 2 || payloadTransaction.templateType == 5">
            <card-payment />
        </div> -->

        <div class="vx-row mt-4">
            <div class="vx-col w-full">
                <label class="font-bold" style="font-size:18px" for="">{{ $t('navMenu.transactionRecent') }}</label>
                <div class="vx-card p-6">
                    <div class="vx-row">
                        <div class="component flex align-button">
                            <vx-tooltip color="primary" :text="$t('navMenu.importTransactionHistory')">
                                <!-- <img class="cursor-pointer pr-2" style="height:25px" src="@/assets/images/logo/import_file.png" alt="Import_File" @click="$router.push('/user/import-file-transaction-history')"> -->
                                <vs-button icon="icon-upload-cloud" iconPack="feather" @click="$router.push('/user/import-file-transaction-history')"></vs-button>
                            </vx-tooltip>
                            <!-- <vx-tooltip color="primary" text="Xuất file đối soát">
                                <img class="cursor-pointer pr-2" style="height:25px" src="@/assets/images/logo/export_file.png" alt="Export_File" @click="$router.push('/user/export-file-transaction-history')">
                            </vx-tooltip> -->
                            <vx-tooltip color="primary" :text="$t('navMenu.checkedTransactionHistory')">
                                <!-- <img class="cursor-pointer pr" style="height:25px" src="@/assets/images/logo/history_export_file.png" alt="History_Export_File" @click="$router.push('/user/reconcile-list-history')"> -->
                                <vs-button class="ml-2" icon="icon-clock" iconPack="feather" @click="$router.push('/user/reconcile-list-history')"></vs-button>
                            </vx-tooltip>
                        </div>
                        <div class="vx-col w-full mt-2">
                            <table-user :dataTransaction="dataTransaction" :headerTable="headerTable" v-if="payloadTransaction.templateType == 1 && dataTransaction" :currentPage="currentPage"/>
                            <table-package :dataTransaction="dataTransaction" :headerTable="headerTable"  v-if="payloadTransaction.templateType == 2 && dataTransaction" :currentPage="currentPage" />
                            <table-payment :paymentType="paymentType" :dataTransaction="dataTransaction" :headerTable="headerTable"  v-if="payloadTransaction.templateType == 5 && dataTransaction" :currentPage="currentPage" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>

import vSelect from 'vue-select'
import DateRangePicker from '../../../components/vue2-daterange-picker/components/DateRangePicker.vue'
import TableUser from './table/TableUser.vue'
import moment from 'moment';
import TablePackage from './table/TablePackage.vue'
import TablePayment from './table/TablePayment.vue'
// import CardPayment from './card/CardPayment.vue'
export default {
    data() {
        return {
            paymentType: null,
            currentPage: 0,
            dataCardUser: [],
            dataTransaction: [],
            transactionsType: [
                {label: this.$t('transaction.comparePayment'), value: 5},
                {label: this.$t('transaction.comparePackages'), value: 2},
                {label: this.$t('transaction.compareAccounts'), value: 1}
            ],
            headerTable: [],
            dateRange: {
                endDate: new Date(),
                startDate: (() => {
                    let date = new Date();
                    date.setDate(date.getDate() - 6);
                    date.setHours(0, 0, 0);
                    return date;
                })(),
            },
            statusTransactionOption: [],
            statusTransactionValue: null,
            organizationOption: [],
            organizationValue: null,
            selectCameraOption: [],
            selectCameraValue: null,
            showListCamera: false,
            payloadTransaction: {
                templateType: 5
            },
            isSelect: false,
            statusTransactionOption: [
                {label: this.$t('menuCamera.all'), value: null},
                {value: 0, label: this.$t('menuCamera.active')},
                {value: 1, label: this.$t('menuCamera.stopWorking')}
            ],
            statusTransactionPackageOption: [
                {label: this.$t('menuCamera.all'), value: null},
                {label: this.$t('statusActive.waiting'), value: 0},
                {label: this.$t('statusActive.agree'), value: 1},
                {label: this.$t('statusActive.decline'), value: 2},
                {label: this.$t('statusActive.cancel'), value: 3},
                {label: this.$t('statusActive.waitingExpire'), value: 4},
                {label: this.$t('statusActive.abort'), value: 5},
                {label: this.$t('statusActive.expries'), value: 6},
                {label: this.$t('statusActive.failure'), value: 7},
            ],
            statusTransactionPaymentOption: [
                {label: this.$t('menuCamera.all'), value: null},
                {label: this.$t('momoPayment.successful'), value: 0},
                {label: this.$t('momoPayment.failure'), value: 1},
            ],
            status: null,
    }
    },

    components: {
        vSelect,
        DateRangePicker,
        TableUser,
        // CardPayment,
        TablePackage,
        TablePayment
    },
    watch: {
        paymentType(value) {
            this.getData();
        }
    },
    computed: {
        transactionSelect: {
            get() {
                let object = this.transactionsType.find(el => el.value === this.payloadTransaction.templateType);
                if (!object) return;
                return {
                    label: object.label,
                    value: object.value
                }
            },
            set(object) {
                this.isSelect = false;
                this.payloadTransaction.templateType = object ? object.value : null;
                this.isSelect = object ? true : false;
                this.dataTransaction.length = 0;
                this.payloadTransaction.status = null;
                this.payloadTransaction.statusPackage = null;
                this.payloadTransaction.statusPayment = null;
            }
        },
        
        statusTransaction: {
            get() {
                let object = this.statusTransactionOption.find(el => el.value === this.status);
                if (!object) return;
                return {
                    label: object.label,
                    value: object.value
                }
            },
            set(object) {
                this.status = object ? object.value : null;
            }
        },

        statusTransactionPackage: {
            get() {
                let object = this.statusTransactionPackageOption.find(el => el.value === this.status);
                if (!object) return;
                return {
                    label: object.label,
                    value: object.value
                }
            },
            set(object) {
                this.status = object ? object.value : null;
            }
        },
        statusTransactionPayment: {
            get(){
                let object = this.statusTransactionPaymentOption.find(el => el.value == this.status);
                if (!object) return;
                return {
                    label: object.label,
                    value: object.value
                }
            },
            set(object) {
                this.status = object ? object.value : null;
            }
        },

        organizationModel: {
            get() {
                return {
                    label: this.getOrganizationString(this.organizationValue),
                    value: this.organizationValue
                }
            },
            set(obj) {
                this.organizationValue = obj.value
                this.showListCamera = true
                this.getCameraByOrganization(obj.value)
            }
        },

        selectCameraModel: {
            get() {
                return {
                    label: this.selectCameraString(this.selectCameraValue),
                    value: this.selectCameraValue
                }
            },
            set(obj) {
                this.selectCameraValue = obj ? obj.value : null;
            }
        }
    },

    created() {
        this.initScreen();
    },
    mounted(){
        this.$eventBus.$on("filter-package", ($event) => {
            this.paymentType = $event;
        })
    },

    methods: {
        formatMoneyIsVND(amount){
            amount = amount.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            return amount.concat(" VND");
        },
        getData(){
            this.payloadTransaction.fromDate = this.dateRange.startDate;
            this.payloadTransaction.toDate = this.dateRange.endDate;
            this.payloadTransaction.status = this.status;
            // this.payloadTransaction.payment = {
            //     type: this.paymentType
            // }
            this.payloadTransaction.cameraPackage = {
                type: this.paymentType
            }
            this.$vs.loading();
            this.$crm.post('template/find-by-condition', this.payloadTransaction).then((res) => {
                let index = 1;
                if (this.payloadTransaction.templateType === 1) {
                    let data = res.data.userResponseDTOS.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
                    this.dataTransaction = data.map(el => {
                        el.createdDate = moment(new Date(el.createdDate)).format("DD/MM/YYYY HH:mm");
                        el.STT = index++;
                        return el;
                    });
                    this.headerTable = [
                        {title: 'STT'},
                        {title: 'Mã'},
                        {title: 'Tên tài khoản'},
                        {title: 'Email'},
                        {title: 'Ngày tạo'},
                        {title: 'Trạng thái'}
                    ]
                    
                }
                if (this.payloadTransaction.templateType === 2) {
                    let data = res.data.cameraPackageHistoryResponseDTOS.sort((a, b) => new Date(b.modifiedDate).getTime() - new Date(a.modifiedDate).getTime());
                    this.dataTransaction = data.map(el => {
                        el.STT = index++;
                        el.modifiedDate = moment(el.modifiedDate).format("DD/MM/YYYY HH:mm");
                        el.cameraPackage.amount = this.formatMoneyIsVND(el.cameraPackage.amount);
                        return el;
                    });
                    // this.dataTransaction = this.dataTransaction.sort((a, b) => b.modifiedDate - a.modifiedDate);
                    this.headerTable = [
                        {title: 'STT'},
                        {title: 'Mã gói'},
                        {title: 'Gói dịch vụ'},
                        {title: 'Tên camera'},
                        {title: 'Giá gói'},
                        {title: 'Dung lượng'},
                        {title: 'Ngày giao dịch'},
                        {title: 'Phương thức giao dịch'},
                        {title: 'Trạng thái'},
                    ]
                }
                if (this.payloadTransaction.templateType === 5) {
                    let data = res.data.momoNotifications.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
                    this.dataTransaction = data.map(el => {
                        el.STT = index++;
                        el.createdDate = moment(new Date(el.createdDate)).format("DD/MM/YYYY HH:mm");
                        el.payType = el.payType.toUpperCase();
                        el.amount = this.formatMoneyIsVND(el.amount);
                        return el;
                    })
                    this.headerTable = [
                        {title: 'STT'},
                        {title: 'Mã hoá đơn'},
                        {title: 'Mã giao dịch'},
                        {title: 'Số tiền giao dịch'},
                        {title: 'Loại giao dịch'},
                        {title: 'Mô tả'},
                        {title: 'Ngày giao dịch'},
                        {title: 'Trạng thái'},
                    ]
                }
                if (this.dataTransaction.length > 0) this.currentPage += 1;
                // this.dataTransaction = this.dataTransaction.reverse();
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
            })
        },
        dateRangePicker: function () {
            this.dateRange.startDate = new Date(
                this.dateRange.startDate.setHours(0, 0, 0)
            );
            this.dateRange.endDate = new Date(
                this.dateRange.endDate.setHours(23, 59, 59)
            );
        },

        getStatusString(id) {
            let status = this.statusTransactionOption.find(x => x.value == id)
            return (status && status.label) || 'Tất cả' 
        },

        getOrganizationString(id) {
            let organization = this.organizationOption.find(x => x.value == id)
            return (organization && organization.label) || 'Tất cả'
        },

        selectCameraString(id) {
            let cameras = this.selectCameraOption.find(x => x.value == id)
            return (cameras && cameras.label) || 'Tất cả'
        },

        initScreen() {
            this.getOrganizationBranch();
            this.dateRangePicker();  
            if (this.payloadTransaction.templateType) this.isSelect = true;
            this.getData();
        },

        getOrganizationBranch() {
            this.$vs.loading();
            this.$crm.post('/organization-branch/all').then(response => {
                this.$vs.loading.close()
                this.organizationOption = response.data.map(item => {
                    item.value = item.id,
                    item.label = item.organizationBranchName
                    return item;
                })
            })
        },

        getCameraByOrganization(id) {
            this.$vs.loading()
            this.$crm.post(`/camera/find-by-organization-branch/${id}`).then(response => {
                this.$vs.loading.close()
                this.selectCameraOption = response.data.map(item => {
                    item.value = item.id,
                    item.label = item.cameraName
                    return item;
                })
            })
        },
    }
}
</script>

<style>
    .align-button {
        position: absolute;
        right: 250px;
        top: 42px;
    }
    .float-right {
        text-align: end;
        align-items: center;
    }

    .label-input {
        font-size: 12px;
        font-weight: bold;
    }
</style>