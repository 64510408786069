<template>
    <vs-table search stripe :max-items="10" pagination :data="dataTransaction" :noDataText="'Không có dữ liệu'" :key="currentPage">
        <template slot="thead">
            <vs-th style="font-size:13px" class="text-center bg-primary text-white"  v-for="(item, itemIndex) in headerTable" :key="itemIndex">{{ item.title }}</vs-th>
        </template>
        <template slot-scope="{data}">
            <tbody>
                <vs-tr class="hoverFlat" :key="indextr" v-for="(compareData, indextr) in data">
                    <vs-td class="md:w-2/12"> {{ compareData.STT }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.id }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.username }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.email }} </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.createdDate }} </vs-td>
                    <vs-td class="md:w-2/12" style="min-width:120px"> 
                        <span class="badge bg-success text-white"  v-if="compareData.active == 0"> {{ $t('menuCamera.active') }} </span>
                        <span class="badge bg-danger text-white" v-else> {{ $t('menuCamera.stopWorking') }} </span>
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
export default {
    props: {
        dataTransaction: [],
        headerTable: [],
        currentPage: 0
    }
}
</script>

<style>
.badge {
    padding: 5px;
    font-size: 12px;
    border-radius: 15px;
}
</style>